import React, { useEffect, useRef, useState } from "react";
import { Notification, Typography } from "@douyinfe/semi-ui";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@state/store";
import { Navigate, useLocation } from "react-router-dom";
import { useRequest } from "ahooks";
import LoadIndicator from "@components/load-indicator";
import { getLoginUserInfo } from "@services/user-manage/user.api";
import { setUser } from "@state/global/reducer";
import { setHasUnread } from "@state/protein-editor/reducer";
import {
  getNotificationInfoApi,
  markNotificationReadApi,
} from "@services/notification/notification.api";
import { downloadFileUrlApi } from "@services/file/file.api";
import { useUserActive } from "@hooks/use-user-active";

const { Text } = Typography;

interface Prop {
  children: any;
}
const LoginGuard: React.FC<Prop> = ({ children }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { data, loading } = useRequest(getLoginUserInfo);
  const location = useLocation();
  const currentPath = location.pathname;

  const userActiveRef = useRef(true);
  const notificationIdShowed = useRef<string[]>([]);
  const [notificationIdKey, setNotificationIdKey] = useState<string>();
  const notificationTimerRef = useRef<NodeJS.Timeout>();
  const notivicationControllerRef = useRef<AbortController>();

  function onDownload(
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    file_id: string
  ) {
    e.stopPropagation();
    e.preventDefault();
    downloadFileUrlApi([file_id])
      .then(({ code, data: { list } }) => {
        const [file] = list;
        if (code === 200 && file.download_url) {
          window.open(file.download_url, "_blank");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async function getNotificationInfo() {
    try {
      const info = await getNotificationInfoApi(
        notificationIdKey ? notificationIdKey : undefined,
        notivicationControllerRef.current?.signal
      );

      dispatch(setHasUnread(info.has_unread));

      setNotificationIdKey(info.last_id);

      if (info.notification_list) {
        console.log("notice-->", info);

        const patchNotifications = info.notification_list.filter((item) => {
          return !notificationIdShowed.current.includes(item.id);
        });

        const newIds = patchNotifications.map((item) => item.id);

        notificationIdShowed.current = [
          ...notificationIdShowed.current,
          ...newIds,
        ];

        patchNotifications.forEach((item) => {
          Notification.info({
            title: item.title,
            content: (
              <>
                <div style={{ maxWidth: "600px" }}>{item.message.text}</div>
                {item.message.c_type === "file_download" && (
                  <div style={{ marginTop: 8 }}>
                    <Text
                      link
                      onClick={(e) => onDownload(e, item.message.file_id)}
                    >
                      Download
                    </Text>
                  </div>
                )}
              </>
            ),
            duration: 0,
            onClose: () => {
              markNotificationReadApi(item.id)
                .then(() => {
                  notificationIdShowed.current =
                    notificationIdShowed.current.filter((id) => {
                      return id !== item.id;
                    });
                })
                .catch((e) => {
                  console.error(e);
                });
            },
            onClick: () => {
              markNotificationReadApi(item.id)
                .then(() => {
                  notificationIdShowed.current =
                    notificationIdShowed.current.filter((id) => {
                      return id !== item.id;
                    });
                })
                .catch((e) => {
                  console.error(e);
                });
            },
          });
        });
      }

      notificationTimerRef.current = setTimeout(
        () => {
          getNotificationInfo();
        },
        userActiveRef.current ? 20000 : 2 * 60 * 1000
      );
    } catch (e) {
      notificationTimerRef.current = setTimeout(
        () => {
          getNotificationInfo();
        },
        userActiveRef.current ? 40000 : 2 * 60 * 1000
      );
    }
  }

  useEffect(() => {
    if (data && data.data) {
      dispatch(setUser(data.data));
    }
  }, [data]);

  useEffect(() => {
    notivicationControllerRef.current = new AbortController();

    getNotificationInfo();

    return () => {
      clearTimeout(notificationTimerRef.current);
      notivicationControllerRef.current?.abort();
    };
  }, []);

  useUserActive({
    onActive: () => {
      userActiveRef.current = true;
    },
    onInactive: () => {
      userActiveRef.current = false;
    },
  });

  if (loading) {
    return <LoadIndicator />;
  } else if (!loading && data && data.code === 200) {
    return children;
  } else {
    return (
      <Navigate
        to={`/login?redirect=${encodeURIComponent(currentPath)}`}
        replace
      />
    );
  }
};

export default LoginGuard;
