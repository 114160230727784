import http, { request } from "@config/http";
import type { ResponseType, AxiosProgressEvent } from "axios";
import { Page, Pagination, List } from "@services/common/model/page.model";
import {
  FileModel,
  FileParamsModel,
  FilePayloadModel,
  FileItemModel,
  CsvDataModel,
  DownloadableFileUrlModel,
  BatchDownloadModel,
} from "./model/file.model";

export const getFileListApi = async (params: FileParamsModel) => {
  const { data } = await http.get<null, Page<Pagination<FileModel>>>(
    `file/all`,
    {
      params,
    }
  );

  return data;
};

export const updateFileApi = async (fid: string, params: FilePayloadModel) => {
  return http.put<null, Page<null>>(`file/${fid}`, {
    ...params,
  });
};

export const deleteFileApi = async (fids: string[]) => {
  return http.post<null, Page<null>>(`file/delete`, { fids });
};

// type FileResult<T> = T extends "text"
//   ? string
//   : T extends "blob"
//   ? Blob
//   : never;

export const downloadFileApi = async (
  fids: string[],
  prefetch?: string,
  responseType?: ResponseType,
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
) => {
  return request.post<null, Blob | string | Page<null>>(
    `file/download_file`,
    { fids },
    {
      responseType,
      headers: {
        prefetch,
      },
      onDownloadProgress: (progressEvent) => {
        if (onDownloadProgress) {
          onDownloadProgress(progressEvent);
        }
      },
    }
  );
};

export const downloadFileByJIdApi = async (
  jids: string[],
  prefetch?: string
) => {
  return request.post<null, Blob | Page<null>>(
    `file/job/download_files`,
    { jids },
    {
      headers: {
        prefetch,
      },
      // responseType: "blob",
    }
  );
};

export const downloadFileByPIdApi = async (
  pids: string[],
  prefetch?: string
) => {
  return request.post<null, Blob | Page<null>>(
    `file/project/download_files`,
    {
      pids,
    },
    {
      headers: {
        prefetch,
      },
      // responseType: "blob",
    }
  );
};

type ViewType = "base" | "list";

type ObjectType<T> = T extends "base"
  ? FileItemModel
  : T extends "list"
  ? FileModel
  : never;

export async function batchFilesApi<T extends ViewType>(
  ids: string[],
  viewType: T
): Promise<ObjectType<T>[]> {
  const { data } = await http.post<null, Page<List<ObjectType<T>>>>(
    `file/batch_get_file_info`,
    {
      file_ids: ids,
      view_type: viewType,
    }
  );

  return data.list;
}

export const downloadCsvFileApi = (fids: string[]) => {
  return request.post<null, string>(`job/read_summary_csv`, { fids });
};

export const getCsvFileContentApi = async (file_id: string, nrows: number) => {
  const { data } = await http.get<null, Page<CsvDataModel>>(
    `file/read_csv_head_n`,
    {
      params: {
        file_id,
        nrows,
      },
    }
  );

  return data;
};

export const downloadFileUrlApi = (fids: string[]) => {
  return request.post<null, Page<List<DownloadableFileUrlModel>>>(
    `file/file_download_urls`,
    { fids }
  );
};

export const batchDownloadFileUrlApi = (params: BatchDownloadModel) => {
  return http.post<null, Page<{ id: string }>>(`file/async_batch_download`, {
    ...params,
  });
};

export const checkNeedAsyncDownloadApi = (params: BatchDownloadModel) => {
  return http.post<null, Page<{ need_async_download: boolean }>>(
    `file/is_need_async_download`,
    {
      ...params,
    }
  );
};
