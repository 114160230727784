import http, { request } from "@config/http";
import { Page, Pagination } from "@services/common/model/page.model";

import {
  NotificationParamsModel,
  NotificationModel,
  FeedbackModel,
  PushPayloadModel,
  NotificationConfigModel,
  NotificationInfoModel,
} from "./model/notification.model";

export const getNotificationListApi = async (
  params: NotificationParamsModel
) => {
  const { data } = await http.get<null, Page<Pagination<NotificationModel>>>(
    `notification/all`,
    {
      params,
    }
  );

  return data;
};

export const deleteNotificationApi = async (nid: string) => {
  return http.delete<null, Page<null>>(`notification/${nid}`, {});
};

export const sendFeedbackApi = async (params: FeedbackModel) => {
  return http.post<null, Page<null>>(`notification/send`, { ...params });
};

export const markNotificationReadApi = async (nid?: string) => {
  return http.post<null, Page<null>>(`notification/mark`, { nid });
};

export const pushNotificationApi = async (params: PushPayloadModel) => {
  return http.post<null, Page<null>>(`notification/admin/send`, { ...params });
};

export const getNotificationConfig = async () => {
  const { data } = await http.get<null, Page<NotificationConfigModel[]>>(
    `notification/info`
  );

  return data;
};

export const getUnsubscribeNotificationConfig = async (token: string) => {
  const { data } = await http.get<null, Page<NotificationConfigModel[]>>(
    `notification/unsubscribe`,
    {
      params: { token },
    }
  );

  return data;
};

export const updateNotificationConfig = async (
  params: NotificationConfigModel[]
) => {
  return http.put<null, Page<NotificationConfigModel[]>>(`notification/info`, {
    notification_info: params,
  });
};

export const updateUnsubscribeNotificationConfig = async (
  token: string,
  params: NotificationConfigModel[]
) => {
  return http.put<null, Page<NotificationConfigModel[]>>(
    `notification/unsubscribe`,
    {
      notification_info: params,
    },
    {
      params: { token },
    }
  );
};

export const getNotificationInfoApi = async (
  last_id?: string,
  signal?: AbortSignal
) => {
  const { data } = await request.get<null, Page<NotificationInfoModel>>(
    `notification/pop_info`,
    {
      signal,
      params: { last_id },
    }
  );

  return data;
};

export const cancelNotificationRedDotApi = async () => {
  const { data } = await http.post<null, Page<null>>(
    `notification/cancel_red_dot`,
    {}
  );

  return data;
};
